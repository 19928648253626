import React, { ReactNode, useEffect, useId, useMemo, useRef } from 'react'
import { MDXProvider } from "@mdx-js/react"
import mermaid from 'mermaid';

mermaid.initialize({});


const MermaidComponent = ({ source, id }: { source: string; id: string }) => {
    const mermaidRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const initializeMermaid = async () => {
            if (mermaidRef.current) {
                mermaidRef.current.innerHTML = source;
                const { svg, bindFunctions } = await mermaid.render(`mermaid-diagram-${id}`, source);
                mermaidRef.current.innerHTML = svg;
                bindFunctions?.(mermaidRef.current);
            }
        };

        initializeMermaid();

        // Clean up mermaid instance when unmounting; doing nothing at the momemt
        return () => {

        };
    }, [source]);

    return <div id={id} ref={mermaidRef}></div>;
};

let id = 0;
const useCustomId = () => useMemo(() => `${++id}-id`, []);

const Code = ({ children, className = '' }: { children: React.ReactNode, className: string | undefined }): ReactNode => {
    const id = useCustomId();
    

    if (className.includes('language-mermaid')) {
        return <MermaidComponent id={id} source={children as string} />
    }

    return <code>{children}</code>
};

export default function Layout({ children }: { children: React.ReactNode }) {
    return (
        <MDXProvider
            components={{
                code: props => <Code className={props.className} children={props.children} />
            }}
        >
            {children}
        </MDXProvider>
    );
}